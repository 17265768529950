import { Service } from 'typedi';
import { BackendHttpClient } from '@infrastructure/http/BackendHttpClient';
import { ResponseMapper } from '@app/mappers/ResponseMapper';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { UIConfigDto } from '@domain/models/UIConfigDto';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { GLDParamDto } from '@domain/enums/GLDParamDto';

@Service()
export class ConfigApi {
  constructor(protected readonly http: BackendHttpClient) {}

  async getConfig(): Promise<UIConfigDto> {
    return this.http.get('/intops/admin/config/ui').then(ResponseMapper.mapToUIConfigDto);
  }

  async getApps(): Promise<GameApplicationDto[]> {
    return this.http.get('/intops/admin/config/games').then((list) => list.map(ResponseMapper.mapToGameApplicationDto));
  }

  async createVariable(variable: ExperimentVariableDto): Promise<ExperimentVariableDto> {
    return this.http.post('/intops/admin/config/variable', variable).then(ResponseMapper.mapToExperimentVariableDto);
  }

  async updateVariable(variable: ExperimentVariableDto): Promise<ExperimentVariableDto> {
    return this.http.put('/intops/admin/config/variable', variable).then(ResponseMapper.mapToExperimentVariableDto);
  }

  async getGlobalAppProperties(): Promise<AppPropertyDto[]> {
    return this.http
      .get('/intops/admin/config/properties')
      .then((list) => list.map(ResponseMapper.mapToApplicationPropertyDto));
  }

  async updateGlobalAppProperties(properties: AppPropertyDto[]): Promise<AppPropertyDto[]> {
    return this.http
      .put('/intops/admin/config/properties', properties)
      .then((list) => list.map(ResponseMapper.mapToApplicationPropertyDto));
  }

  async getControlGroups(gameId: number): Promise<ControlGroupByCountry> {
    return this.http.get(`/intops/admin/config/${gameId}/control-group`);
  }

  async getGLDParams(gameId: number): Promise<GLDParamDto[]> {
    return this.http.get(`/intops/admin/config/variable/gld/${gameId}`);
  }
}
