import React from 'react';
import { memoize } from 'proxy-memoize';

import { useToggle } from '@ui/hooks/useToggle';

import { ExpDefinitionSummary } from '@pages/experimentDetails/components/summary/config/definition/ExpDefinitionSummary';
import { ExpDefinitionEditor } from '@pages/experimentDetails/components/summary/config/definition/ExpDefinitionEditor';
import { FormControls } from '@pages/experimentDetails/components/summary/config/atoms/FormControls';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { useForm } from '@ui/hooks/form';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { pushUpdateExperimentDefinition } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';

export function ExpDefinitionContainer() {
  const dispatch = useDispatch();

  const { isActive: editorEnabled, handleOpen: openEditor, handleClose: closeEditor } = useToggle(false);

  const form = useSelector(memoize(experimentDetailsSelectors.getExpDefinitionParams));
  const { definitionUpdate: status } = useSelector(experimentDetailsSelectors.getSummaryEditorStatus);
  const isEditorLoading = useSelector(experimentDetailsSelectors.getIsSummaryEditorLoading);

  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    control,
    formState: { errors, isValid },
    handleSubmit
  } = useForm<ExpDefinitionParams>({
    schema: ExpDefinitionParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushUpdateExperimentDefinition.trigger(params));
    closeEditor();
  });

  const controlsDisabled = isEditorLoading || !isValid;

  return (
    <>
      {editorEnabled ? (
        <ExpDefinitionEditor control={control} register={register} errors={errors} />
      ) : (
        <ExpDefinitionSummary />
      )}
      <FormControls
        openEditor={openEditor}
        closeEditor={closeEditor}
        editorEnabled={editorEnabled}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        disabled={controlsDisabled}
      />
    </>
  );
}
