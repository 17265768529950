import { ActionFactory } from '@infrastructure/store/helpers/ActionFactory';
import { AppConfigDto } from '@domain/models/local/AppConfig';
import {
  CreateExpVariableParams,
  UpdateExpVariableParams
} from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

const namespace = 'config';

export const fetchAppConfig = ActionFactory.createRequestRoutine<void, AppConfigDto>('APP_CONFIG_FETCH', namespace);

export const pushCreateVariable = ActionFactory.createRequestRoutine<CreateExpVariableParams, ExperimentVariableDto>(
  'EXP_VAR_CREATE_PUSH',
  namespace
);
export const pushUpdateVariable = ActionFactory.createRequestRoutine<UpdateExpVariableParams, ExperimentVariableDto>(
  'EXP_VAR_UPDATE_PUSH',
  namespace
);
