import { Dto } from '@domain/models/Dto';
import { IsNotEmpty, IsNumber, IsString, IsBoolean, IsInt, MaxLength } from 'class-validator';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';

export class UpdateExperimentObjectiveDto extends Dto {
  @IsInt()
  @IsNotEmpty()
  public id: number;

  @IsString()
  @MaxLength(200)
  public description: string;

  public configs: GenericConfigEntry[];
  public controlGroup: Record<string, object>;

  @IsBoolean()
  public newUsers: boolean;

  @IsNumber()
  public usersAllocationPercent: number;

  @IsBoolean()
  public adjustableUsersAllocation: boolean;

  @IsBoolean()
  public cloneControlGroup: boolean;

  @IsBoolean()
  public builtInGroup: boolean;

  @IsInt()
  public primaryRegion: number; // Region Id

  @IsInt({ each: true })
  public regions: number[];

  @IsInt({ each: true })
  public regionsForInDev: number[];

  @IsString({ each: true })
  public appVersions: string[];
}
