import { Dto } from '@domain/models/Dto';

import { DevBasicInfoParams } from '@domain/models/experimentDetails/devPhase/DevBasicInfoParams';
import { DevTargetingParams } from '@domain/models/experimentDetails/devPhase/DevTargetingParams';
import { DevObjConfigParams } from '@domain/models/experimentDetails/devPhase/DevObjConfigParams';

export class DevPhaseParams extends Dto {
  public basicInfo: DevBasicInfoParams;
  public targetingConfig: DevTargetingParams;
  public objectiveConfig: DevObjConfigParams;

  static ofInitial() {
    const form = new DevPhaseParams();

    form.basicInfo = DevBasicInfoParams.ofInitial();
    form.targetingConfig = DevTargetingParams.ofInitial();
    form.objectiveConfig = DevObjConfigParams.ofInitial();

    return form;
  }
}
