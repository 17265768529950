import { combineReducers } from '@reduxjs/toolkit';

import { pushLogout } from '@infrastructure/store/auth/authActions';

import { auth } from '@infrastructure/store/auth/authReducer';
import { config } from '@infrastructure/store/config/configReducer';
import { experimentList } from '@infrastructure/store/experimentList/experimentListReducer';
import { applicationProperties } from '@infrastructure/store/applicationProperties/appPropertiesReducer';
import { userManagement } from '@infrastructure/store/userManagement/userManagementReducer';
import { createExperiment } from '@infrastructure/store/createExperiment/createExperimentReducer';
import { experimentDetails } from '@infrastructure/store/experimentDetails/experimentDetailsReducer';
import { appliedConfig } from '@infrastructure/store/appliedConfig/appliedConfigReducer';

const appReducer = combineReducers({
  auth,
  config,
  experimentList,
  userManagement,
  applicationProperties,
  appliedConfig,
  createExperiment,
  experimentDetails
});

export function rootReducer(state, action) {
  console.log(action);
  if (action.type === pushLogout.TRIGGER) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
}
