import React from 'react';
import { Control, UseFormResetField, UseFormWatch } from 'react-hook-form';

import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { RegionTypeInput } from '@pages/createExperiment/targetConfig/inputs/RegionTypeInput';
import { RegionsInput } from '@pages/createExperiment/targetConfig/inputs/RegionsInput';
import { LiveRegionsInput } from '@pages/createExperiment/targetConfig/inputs/LiveRegionsInput';
import { InDevRegionsInput } from '@pages/createExperiment/targetConfig/inputs/InDevRegionsInput';
import { PrimaryRegionInput } from '@pages/createExperiment/targetConfig/inputs/PrimaryRegionInput';
import { VersionsInput } from '@pages/createExperiment/targetConfig/inputs/VersionsInput';
import { NextButton } from '@pages/createExperiment/atoms/NextButton/NextButton';
import { GameStatsContainer } from '@pages/createExperiment/targetConfig/components/gameStats/GameStatsContainer';
import { FormComponent } from '@ui/hooks/form';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

type Props = FormComponent<TargetConfigParams> & {
  watch: UseFormWatch<TargetConfigParams>;
  control: Control<TargetConfigParams>;
  resetField: UseFormResetField<TargetConfigParams>;
  experimentType: ExperimentType;
  isValid: boolean;
};

export function TargetingConfig({
  isValid,
  handleSubmit,
  watch,
  control,
  register,
  resetField,
  experimentType
}: Props) {
  let regionInputs;

  switch (experimentType) {
    case ExperimentType.AB_TESTING:
      regionInputs = (
        <>
          <RegionTypeInput control={control} />
          <RegionsInput control={control} watch={watch} resetField={resetField} />
        </>
      );
      break;
    case ExperimentType.GLD_TEST:
      regionInputs = (
        <>
          <LiveRegionsInput control={control} resetField={resetField} />
          <PrimaryRegionInput control={control} watch={watch} resetField={resetField} />
          <InDevRegionsInput control={control} resetField={resetField} />
        </>
      );
      break;
    default:
      throw new TypeError(`Experiment type: ${experimentType} is not supported`);
  }

  return (
    <>
      <GameStatsContainer />
      <form className={styles.targetConfig} onSubmit={handleSubmit}>
        {regionInputs}
        <VersionsInput register={register} control={control} />

        <NextButton disabled={!isValid} />
      </form>
    </>
  );
}
