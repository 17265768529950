import { ArrayNotEmpty, IsArray, IsEnum, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

import { ExpFormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { ExperimentRegion, ExperimentRegionName } from '@domain/enums/ExperimentRegion';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { VersionInput } from '@domain/models/VersionInput';

export class RegionOption {
  value: string;
  label: string;
}

export class TargetConfigParams {
  @IsEnum(ExperimentRegion)
  @IsNotEmpty()
  regionType: string;

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regions: RegionOption[];

  @IsString()
  @IsNotEmpty()
  primaryRegion: string;

  @IsArray()
  @ArrayNotEmpty()
  @ValidateNested({ each: true })
  @Type(() => RegionOption)
  regionsForInDev: RegionOption[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  versions: VersionInput[];

  static ofInitial() {
    const form = new TargetConfigParams();

    form.regionType = ExperimentRegion.US;
    form.regions = [{ value: ExperimentRegionName.US, label: ExperimentRegionName.US }];
    form.primaryRegion = ExperimentRegionName.US;
    form.regionsForInDev = [{ value: ExperimentRegionName.MK, label: ExperimentRegionName.MK }];
    form.versions = [{ value: '' }];

    return form;
  }

  public pushRegions(regionName: RegionOption) {
    this.regions.push(regionName);
    return this;
  }

  public setVersion(version: string) {
    this.versions = [{ value: version }];
    return this;
  }

  public setRegionType(regionType: ExperimentRegion) {
    this.regionType = regionType;
    return this;
  }

  public setRegions(regionNames: string[]) {
    this.regions = regionNames.map((regionName) => ({ value: regionName, label: regionName }));
    return this;
  }

  getSummary(experimentType: ExperimentType): ExpFormSummary {
    if (experimentType === ExperimentType.GLD_TEST) {
      return [
        { title: 'Live Regions', value: this.regions.map(({ value }) => value).join(', ') },
        { title: 'Decisioning Region', value: this.primaryRegion },
        { title: 'In Dev Regions', value: this.regionsForInDev.map(({ value }) => value).join(', ') },
        {
          title: 'Versions',
          value: this.versions.filter((x) => x.value.trim().length).length
            ? this.versions
                .filter(({ value }) => value)
                .map(({ value }) => value)
                .join(', ')
            : 'All versions'
        }
      ];
    }

    return [
      { title: 'Countries', value: this.regions.map(({ value }) => value).join(', ') },
      {
        title: 'Versions',
        value: this.versions.filter((x) => x.value.trim().length).length
          ? this.versions
              .filter(({ value }) => value)
              .map(({ value }) => value)
              .join(', ')
          : 'All versions'
      }
    ];
  }
}
