import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { isEmpty } from 'lodash-es';

import { RouteParams } from '@infrastructure/routing';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { RequestStage } from '@infrastructure/store/types/actions';
import { fetchDetailsView } from '@infrastructure/store/experimentDetails/experimentDetailsActions';

import ExperimentDetails from '@pages/experimentDetails/ExperimentDetails';
import { PageLoader } from '@components/layout/Loader';
import { NotFound } from '@pages/common/NotFound';

export function ExperimentDetailsContainer() {
  const dispatch = useDispatch();
  const { experimentId } = useParams<RouteParams.GetExperiment>();
  const expId = Number(experimentId);

  const status = useSelector(experimentDetailsSelectors.getViewStatus);

  const experiment = useSelector(experimentDetailsSelectors.getExperiment);
  const { experimentType, state } = experiment;

  useEffect(() => {
    dispatch(fetchDetailsView.trigger(expId));
  }, [dispatch, expId]);

  if (status !== RequestStage.SUCCESS) {
    return <PageLoader />;
  }

  if (isEmpty(experiment)) {
    return <NotFound />;
  }

  return <ExperimentDetails experimentType={experimentType} state={state} />;
}
