import { IsArray, IsNotEmpty, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class DevObjInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | null;
}

export class DevObjFormConfig {
  constructor(name: string, input: DevObjInput[], description: string, active: boolean) {
    this.name = name;
    this.description = description;
    this.input = input;
    this.active = active;
  }

  name: string;
  description: string;
  input: DevObjInput[];
  active: boolean;
}

class Param {
  @IsString()
  @IsNotEmpty()
  value: string;
}

export class DevObjConfigParams {
  @IsString()
  defaultValue: string;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Param)
  params: Param[];

  @IsArray()
  config: DevObjFormConfig[];

  static ofInitial() {
    const form = new DevObjConfigParams();

    form.defaultValue = '';
    form.params = [];
    form.config = [];

    return form;
  }
}
