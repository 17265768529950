import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/ABForm.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'register'>;

export function NewUsersInput({ register }: Props) {
  return (
    <CheckboxInput
      labelText="New Users"
      className={styles.input}
      {...getInputProps<ObjectiveConfigParams>(`newUsers`, register, {})}
    />
  );
}
