import React from 'react';
import { Control, UseFormResetField, UseFormWatch } from 'react-hook-form';
import cn from 'classnames';

import { FormComponent } from '@ui/hooks/form';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import { LiveRegionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/LiveRegionsInput';

import { PrimaryRegionInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/PrimaryRegionInput';
import { InDevRegionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/InDevRegionsInput';
import { VersionsInput } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/inputs/VersionsInput';
import { GameStatsContainer } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/GameStatsContainer';

import styles from '../../DevPhase.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control' | 'register' | 'errors'> & {
  watch: UseFormWatch<DevPhaseParams>;
  control: Control<DevPhaseParams>;
  resetField: UseFormResetField<DevPhaseParams>;
};

export function TargetingConfigSection({ watch, control, register, resetField }: Props) {
  return (
    <div>
      <div className={styles.gameStats}>
        <GameStatsContainer />
      </div>
      <ul className={cn(styles.formSection, styles.devPhase)}>
        <PrimaryRegionInput control={control} watch={watch} resetField={resetField} />
        <LiveRegionsInput control={control} resetField={resetField} />
        <InDevRegionsInput control={control} resetField={resetField} />
        <VersionsInput register={register} control={control} />
      </ul>
    </div>
  );
}
