import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'crazy-ui-next';

import { useForm } from '@ui/hooks/form';
import { useDispatch } from '@ui/hooks/redux';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { pushUpdateDevPhase } from '@infrastructure/store/experimentDetails/experimentDetailsActions';

import { ConfigSection } from '@pages/experimentDetails/components/summary/config/atoms/ConfigSection';
import { BasicInfoSection } from '@pages/experimentDetails/components/summary/config/devPhase/sections/BasicInfoSection';
import { TargetingConfigSection } from '@pages/experimentDetails/components/summary/config/devPhase/sections/targetingConfig/TargetingConfigSection';
import { ConfigInputContainer } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/ConfigInputContainer';

import { RequestStage } from '@infrastructure/store/types/actions';

import styles from './DevPhase.module.scss';

export function DevPhaseForm() {
  const dispatch = useDispatch();

  const form = useSelector(experimentDetailsSelectors.getDevPhaseParams);
  const status = useSelector(experimentDetailsSelectors.getDevPhaseStatus);
  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    formState: { errors },
    resetField,
    control,
    watch,
    handleSubmit,
    setFocus
  } = useForm<DevPhaseParams>({
    schema: DevPhaseParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => dispatch(pushUpdateDevPhase.trigger(params)));

  return (
    <div className={styles.config}>
      <main>
        <ConfigSection
          className={styles.fullWidth}
          label="Basic Info:"
          children={<BasicInfoSection register={register} errors={errors} />}
        />
        <ConfigSection
          className={styles.fullWidth}
          label="Objective Config:"
          children={<ConfigInputContainer watch={watch} control={control} setFocus={setFocus} />}
        />
        <ConfigSection
          className={styles.fullWidth}
          label="Targeting Config:"
          children={
            <TargetingConfigSection
              control={control}
              register={register}
              errors={errors}
              resetField={resetField}
              watch={watch}
            />
          }
        />
        <div className={styles.formControls}>
          <Button onClick={handleFormSubmit} isLoading={isLoading}>
            Save
          </Button>
        </div>
      </main>
    </div>
  );
}
