import React, { useCallback } from 'react';
import { TextInput } from 'crazy-ui-next';
import { useFieldArray } from 'react-hook-form';

import { FormComponent, getInputProps } from '@ui/hooks/form';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import styles from '@pages/experimentDetails/ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'register' | 'errors' | 'control'>;

export function AppVersionInput({ register, errors, control }: Props) {
  const maxInputsLength = 5;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'appVersions'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => (
    <TextInput
      key={field.id}
      className={styles.editorInput}
      {...getInputProps<ExpDefinitionParams>(`appVersions.${index}.value`, register, errors)}
    />
  ));

  return (
    <>
      <p>App Versions:</p>
      <MultiTextInputContainer
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
        className={styles.multiInput}
      />
    </>
  );
}
