import { createReducer, combineReducers } from '@reduxjs/toolkit';

import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';
import { gameStatsReducer } from '@infrastructure/store/experimentDetails/reducers/gameStatsReducer';
import { iterationsReducer } from '@infrastructure/store/experimentDetails/reducers/iterationsReducer';
import { defaultRegionsDataReducer } from '@infrastructure/store/experimentDetails/reducers/defaultRegionsReducer';
import { chartsReducer } from '@infrastructure/store/experimentDetails/reducers/chartsReducers';
import { auditReducer } from '@infrastructure/store/experimentDetails/reducers/auditReducers';
import { logsReducer } from '@infrastructure/store/experimentDetails/reducers/logsReducers';
import {
  clearAll,
  setSelectedObjective,
  pushUpdateExperimentDefinition,
  pushUpdateExpObjective,
  pushStopGroups,
  applyExperimentWinner,
  updateExperimentWinner,
  deleteExperimentWinner,
  pushUpdateDevPhase,
  fetchExperiment,
  pushStopObjective,
  pushRestoreObjective,
  fetchDefaultRegions,
  fetchDetailsView,
  pushCancelExperiment,
  pushScheduleGLDExperiment
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { ExperimentMapper } from '@app/mappers/ExperimentMapper';

const experimentInitial: ExperimentDto = {} as ExperimentDto;

export const experimentReducer = createReducer(experimentInitial, (qb) => {
  qb.addCase(fetchExperiment.success, (state, { payload }) => {
    return payload;
  });

  qb.addCase(clearAll, () => {
    return experimentInitial;
  });
});

const selectedObjectiveInitial: number = 0;

export const selectedObjectiveReducer = createReducer(selectedObjectiveInitial, (qb) => {
  qb.addCase(fetchExperiment.success, (state, { payload }) => {
    const { experimentObjectives } = payload;
    const regions = ExperimentMapper.extractRegions(payload.experimentObjectives);
    const objective = experimentObjectives.find((obj) => obj.primaryRegion.name === regions[0]);

    return objective ? objective.id : experimentObjectives[0].id;
  });

  qb.addCase(setSelectedObjective, (state, { payload }) => {
    return payload;
  });

  qb.addCase(clearAll, () => {
    return selectedObjectiveInitial;
  });
});

const defaultRegionsReducer = combineReducers({
  data: defaultRegionsDataReducer,
  status: ReducerFactory.makeStatus(fetchDefaultRegions, clearAll)
});

const summaryEditorStatusReducer = combineReducers({
  definitionUpdate: ReducerFactory.makeStatus(pushUpdateExperimentDefinition, clearAll),
  objectiveUpdate: ReducerFactory.makeStatus(pushUpdateExpObjective, clearAll),
  groupsUpdate: ReducerFactory.makeStatus(pushStopGroups, clearAll)
});

export const experimentDetails = combineReducers({
  experiment: experimentReducer,
  selectedObjectiveId: selectedObjectiveReducer,
  gameStats: gameStatsReducer,
  defaultRegions: defaultRegionsReducer,
  iterations: iterationsReducer,
  charts: chartsReducer,
  audit: auditReducer,
  logs: logsReducer,

  expStatus: ReducerFactory.makeStatus(fetchExperiment, clearAll),
  viewStatus: ReducerFactory.makeStatus(fetchDetailsView, clearAll),
  summaryEditorStatus: summaryEditorStatusReducer,
  devPhaseStatus: ReducerFactory.makeStatus(pushUpdateDevPhase, clearAll),
  actionsStatus: ReducerFactory.makeStatus([pushScheduleGLDExperiment, pushCancelExperiment], clearAll),
  winnerStatus: ReducerFactory.makeStatus(
    [applyExperimentWinner, updateExperimentWinner, deleteExperimentWinner, pushStopObjective, pushRestoreObjective],
    clearAll
  )
});
