export class DevBasicInfoParams {
  public experimentName: string;
  public arpu: string;
  public description: string;

  static ofInitial() {
    const params = new DevBasicInfoParams();

    params.experimentName = '';
    params.arpu = '';
    params.description = '';

    return params;
  }
}
