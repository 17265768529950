import { Service } from 'typedi';
// eslint-disable-next-line import/no-extraneous-dependencies
import crypto from 'crypto-js';

import { ExperimentApi } from '@infrastructure/api/ExperimentApi';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ExperimentUseCase } from '@domain/useCases/ExperimentUseCase';
import { UniqueId } from '@domain/types';
import { MessageResponseDto } from '@domain/models/MessageResponseDto';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { ExperimentDetailsMapper } from '@app/mappers/experiment/ExperimentDetailsMapper';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { IterationDto } from '@domain/models/iteration/IterationDto';
import { AuditDto } from '@domain/models/experiment/AuditDto';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { IterationStatus } from '@domain/enums/IterationStatus';

@Service()
export class ExperimentService implements ExperimentUseCase {
  constructor(private readonly reduxRepo: ReduxRepo, private readonly experimentApi: ExperimentApi) {}

  async getExperiment(experimentId: UniqueId): Promise<ExperimentDto> {
    return this.experimentApi.getExperiment(experimentId);
  }

  async updateDevPhase(devPhaseParams: DevPhaseParams) {
    const experiment = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const defaultRegions = this.reduxRepo.findBy(experimentDetailsSelectors.getDefaultRegionsData);

    const { expUpdateParams, objUpdateParams } = ExperimentDetailsMapper.mapDevPhaseParamsToUpdateDtos(
      devPhaseParams,
      experiment,
      defaultRegions
    );

    await this.experimentApi.updateExperiment(experiment.experimentId, expUpdateParams);
    await this.experimentApi.updateExperimentObjective(experiment.experimentId, objUpdateParams);
  }

  async updateDefinition(body: ExpDefinitionParams): Promise<MessageResponseDto> {
    const experiment = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);

    const updateDto = ExperimentDetailsMapper.mapDefinitionParamsToUpdateDto(body, experiment);

    return this.experimentApi.updateExperiment(experiment.experimentId, updateDto);
  }

  async updateObjective(body: ExpObjectiveConfigParams): Promise<MessageResponseDto> {
    const { experimentId } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const objectiveId = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedObjectiveId);

    const updateDto = ExperimentDetailsMapper.mapObjectiveParamsToUpdateDto(body, objectiveId);

    return this.experimentApi.updateExperimentObjective(experimentId, updateDto);
  }

  async stopGroups(body: ExpGroupsConfigParams): Promise<MessageResponseDto> {
    const { experimentId } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const objectiveId = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedObjectiveId);

    const stoppedGroups = ExperimentDetailsMapper.mapGroupsParamsToUpdateDto(body);

    return this.experimentApi.runNextConfig(experimentId, objectiveId, stoppedGroups);
  }

  async restoreObjective(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.experimentApi.restoreObjective(experimentId, objectiveId);
  }

  async stopObjective(experimentId: UniqueId, objectiveId: UniqueId): Promise<MessageResponseDto> {
    return this.experimentApi.stopObjective(experimentId, objectiveId);
  }

  async getExperimentIterations(expId: UniqueId): Promise<IterationDto[]> {
    return this.experimentApi.getExperimentIterations(expId);
  }

  async getIterationResult(): Promise<{ result: IterationResultDto[]; cacheKey: string; isNew: boolean }> {
    const { experimentType } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);
    const filters = this.reduxRepo.findBy(experimentDetailsSelectors.getIterationFilters);
    const { iterationId } = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedIteration);

    const cacheKey = crypto.SHA256(`${iterationId}${filters.region}${filters.version}${filters.arpu}`).toString();
    const cacheResult = this.reduxRepo.findBy(experimentDetailsSelectors.getIterationResultFromCache(cacheKey));

    if (cacheResult) {
      return { cacheKey, result: cacheResult, isNew: false };
    }

    const { results } = await this.experimentApi.getIterationResult(experimentType, iterationId, filters);

    return { cacheKey, result: results, isNew: true };
  }

  async getLastDoneIterationWithResultsId(): Promise<IterationDto | null> {
    const iterations = this.reduxRepo.findBy(experimentDetailsSelectors.getIterationsByFilter);
    const nextCheckDate = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedNextCheckDate);

    const doneIterations = iterations?.filter((iteration) => iteration.state === IterationStatus.DONE) || [];
    const doneIterationByDate = doneIterations.find((iteration) => iteration.nextCheckDate === nextCheckDate);

    if (doneIterationByDate) {
      return doneIterationByDate;
    }

    if (doneIterations.length) {
      const doneIterationsWithResults = doneIterations.filter((iteration) => Boolean(iteration.results.length));

      let lastDoneIteration: IterationDto;

      if (doneIterationsWithResults.length) {
        lastDoneIteration = doneIterationsWithResults[doneIterationsWithResults.length - 1];
      } else {
        lastDoneIteration = doneIterations[doneIterations.length - 1];
      }

      return lastDoneIteration;
    }

    return null;
  }

  async getAudit(): Promise<AuditDto[]> {
    const { experimentId } = this.reduxRepo.findBy(experimentDetailsSelectors.getExperiment);

    return this.experimentApi.getExperimentAudit(experimentId);
  }

  async requestAnalysis(): Promise<MessageResponseDto> {
    const { iterationId } = this.reduxRepo.findBy(experimentDetailsSelectors.getSelectedIteration);

    return this.experimentApi.requestAnalysis(iterationId);
  }

  async scheduleGLD(experimentId: UniqueId): Promise<MessageResponseDto> {
    return this.experimentApi.scheduleGldExperiment(experimentId);
  }

  async cancelExperiment(experimentId: UniqueId): Promise<MessageResponseDto> {
    return this.experimentApi.cancelExperiment(experimentId);
  }
}
