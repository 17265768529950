import { call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { Service } from 'typedi';

import { RootSagaWatcher } from '@infrastructure/sagas/RootSagaWatcher';
import { BaseSagaWatcher } from '@infrastructure/sagas/BaseSagaWatcher';
import {
  pushBasicInfoForm,
  pushTargetConfigForm,
  pushABObjectiveForm,
  pushGLDObjectiveForm,
  pushGoalConfigForm,
  pushDatesConfigForm,
  pushAdvancedConfigForm,
  pushValidateABObjectiveForm,
  pushNextStep,
  fetchRegions,
  fetchGameStats,
  initTargetConfig,
  generateTargetConfig,
  fetchObjectiveConfig,
  pushExperimentClone,
  pushExperimentCreate,
  fetchGameInstallsStats,
  pushValidateGLDObjectiveForm,
  validateOverlapping,
  initABObjectiveConfig,
  initGLDObjectiveConfig,
  fetchGLDParams,
  generateABObjectiveConfig,
  submitObjectivesStep,
  initGoalConfig,
  initDatesConfig,
  generateGoalConfig,
  generateDatesConfig
} from '@infrastructure/store/createExperiment/createExperimentActions';
import { Notification } from '@infrastructure/api/notifications';
import { CreateExperimentService } from '@app/services/CreateExperimentService';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';

@Service()
export class CreateExperimentSaga extends BaseSagaWatcher {
  constructor(private createExperimentService: CreateExperimentService, protected rootWatcher: RootSagaWatcher) {
    super(rootWatcher);
  }

  getEffects() {
    return [
      takeLatest(pushBasicInfoForm, this.handleNextStep.bind(this)),
      takeLatest(pushTargetConfigForm, this.handleNextStep.bind(this)),
      takeLatest(pushABObjectiveForm, this.triggerValidateABObjectiveConfig.bind(this)),
      takeLatest(pushGLDObjectiveForm, this.validateGLDObjectiveConfig.bind(this)),
      takeLatest(pushGoalConfigForm, this.handleNextStep.bind(this)),
      takeLatest(pushDatesConfigForm, this.handleNextStep.bind(this)),
      takeLatest(pushAdvancedConfigForm, this.handleNextStep.bind(this)),

      takeLatest(initTargetConfig, this.fetchRegions.bind(this)),
      takeLatest(initGoalConfig, this.generateGoalConfig.bind(this)),
      takeLatest(initDatesConfig, this.generateDatesConfig.bind(this)),
      takeLatest(initTargetConfig, this.fetchGameStats.bind(this)),
      takeLatest(fetchGameStats.success, this.generateTargetConfig.bind(this)),

      takeLatest(initABObjectiveConfig, this.fetchObjectiveConfig.bind(this)),
      takeLatest(initABObjectiveConfig, this.validateOverlappingExperiments.bind(this)),
      takeLatest(initGLDObjectiveConfig, this.validateOverlappingExperiments.bind(this)),
      takeLatest(initGLDObjectiveConfig, this.fetchGLDParams.bind(this)),
      takeEvery(fetchGameInstallsStats.trigger, this.fetchGameInstallsStats.bind(this)),
      takeLatest(fetchObjectiveConfig.success, this.generateABObjectiveConfig.bind(this)),

      takeLatest(pushValidateABObjectiveForm.trigger, this.validateABObjectiveConfig.bind(this)),
      takeLatest(pushValidateABObjectiveForm.success, this.shouldSubmitObjectiveForm.bind(this)),

      takeLatest(pushValidateGLDObjectiveForm.success, this.handleNextStep.bind(this)),

      takeLatest(submitObjectivesStep.trigger, this.submitObjectiveConfigStep.bind(this)),

      takeLatest(pushExperimentCreate.trigger, this.pushExperimentCreate.bind(this)),
      takeLatest(pushExperimentCreate.success, this.afterCreate.bind(this)),
      takeLatest(pushExperimentClone.trigger, this.pushCloneExperiment.bind(this))
    ];
  }

  public *handleNextStep() {
    yield put(pushNextStep());
  }

  public *fetchRegions() {
    try {
      yield put(fetchRegions.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.fetchRegions]);

      yield put(fetchRegions.success(result));
    } catch (err) {
      yield put(fetchRegions.failure());
      Notification.error(err);
    }
  }

  public *fetchGameStats() {
    try {
      yield put(fetchGameStats.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.fetchGameStats]);

      yield put(fetchGameStats.success(result));
    } catch (err) {
      yield put(fetchGameStats.failure());
      Notification.warning('Failed to fetch game stats');
    }
  }

  public *generateTargetConfig() {
    try {
      yield put(generateTargetConfig.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.generateTargetConfig]);

      if (result.isRecommended) {
        yield put(pushNextStep());
      }

      yield put(generateTargetConfig.success(result));
    } catch (err) {
      yield put(generateTargetConfig.failure());
      Notification.error(err);
    }
  }

  public *generateGoalConfig() {
    try {
      yield put(generateGoalConfig.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.generateGoalConfig]);

      if (result.isRecommended) {
        yield put(pushNextStep());
      }

      yield put(generateGoalConfig.success(result));
    } catch (err) {
      yield put(generateGoalConfig.failure());
      Notification.error(err);
    }
  }

  public *generateDatesConfig() {
    try {
      yield put(generateDatesConfig.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.generateDatesConfig]);

      if (result.isRecommended) {
        yield put(pushNextStep());
      }

      yield put(generateDatesConfig.success(result));
    } catch (err) {
      yield put(generateDatesConfig.failure());
      Notification.error(err);
    }
  }

  public *fetchGameInstallsStats(action: ReturnType<typeof fetchGameInstallsStats.trigger>) {
    try {
      yield put(fetchGameInstallsStats.request());

      const result = yield call(
        [this.createExperimentService, this.createExperimentService.fetchGameInstallsStats],
        action.payload
      );

      yield put(fetchGameInstallsStats.success(result));
    } catch (err) {
      yield put(fetchGameInstallsStats.failure());
      Notification.warning('Failed to fetch game installs');
    }
  }

  public *fetchObjectiveConfig() {
    try {
      yield put(fetchObjectiveConfig.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.fetchControlGroups]);

      yield put(fetchObjectiveConfig.success(result));
    } catch (err) {
      yield put(fetchObjectiveConfig.failure());
      Notification.warning('Failed to fetch control group from appsdb');
    }
  }

  public *fetchGLDParams() {
    try {
      yield put(fetchGLDParams.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.getGLDParams]);

      yield put(fetchGLDParams.success(result));
    } catch (err) {
      yield put(fetchGLDParams.failure());
      Notification.warning('Failed to fetch imported GLD params');
    }
  }

  public *generateABObjectiveConfig() {
    try {
      yield put(generateABObjectiveConfig.request());
      const result = yield call([this.createExperimentService, this.createExperimentService.generateABObjectiveConfig]);

      yield put(generateABObjectiveConfig.success(result));

      if (result.isRecommended) {
        yield put(pushNextStep());
      }
    } catch (err) {
      yield put(generateABObjectiveConfig.failure());
      Notification.error(err);
    }
  }

  public *validateGLDObjectiveConfig(action: ReturnType<typeof pushGLDObjectiveForm>) {
    try {
      yield put(pushValidateGLDObjectiveForm.request());

      yield call([this.createExperimentService, this.createExperimentService.validateGLDConfig], action.payload);

      yield put(pushValidateGLDObjectiveForm.success());
    } catch (err) {
      yield put(pushValidateGLDObjectiveForm.failure());
      Notification.error(err);
    }
  }

  public *triggerValidateABObjectiveConfig(action: ReturnType<typeof pushABObjectiveForm>) {
    yield put(pushValidateABObjectiveForm.trigger(action.payload));
  }

  public *validateABObjectiveConfig(action: ReturnType<typeof pushABObjectiveForm>) {
    try {
      yield put(pushValidateABObjectiveForm.request());
      const { region, form } = action.payload;
      const configList = yield call(
        [this.createExperimentService, this.createExperimentService.validateABConfig],
        form
      );
      const result = { region, configList };

      yield put(pushValidateABObjectiveForm.success(result));
    } catch (err) {
      yield put(pushValidateABObjectiveForm.failure());
      Notification.error(err);
    }
  }

  public *shouldSubmitObjectiveForm() {
    const form = yield select(createExperimentSelectors.getForm);
    const { regions } = form[CreateExperimentForm.TARGET_CONFIG];
    const configList = yield select(createExperimentSelectors.getConfigList);

    /*
      form is finished when for each region we have validated config
      it means we can proceed to another section
    */
    const isFormFinished = regions.every((region) => configList[region]);

    if (isFormFinished) {
      yield put(pushNextStep());
    }
  }

  public *pushExperimentCreate() {
    try {
      yield put(pushExperimentCreate.request());

      yield call([this.createExperimentService, this.createExperimentService.createExperiment]);

      yield put(pushExperimentCreate.success());
      Notification.success('The experiment has been created');
    } catch (err) {
      yield put(pushExperimentCreate.failure());
      Notification.error(err);
    }
  }

  public *pushCloneExperiment() {
    try {
      yield put(pushExperimentClone.request());

      const payload = yield call([this.createExperimentService, this.createExperimentService.cloneExperiment]);

      yield put(pushExperimentClone.success(payload));
    } catch (err) {
      yield put(pushExperimentClone.failure());
      Notification.error(err);
    }
  }

  public *validateOverlappingExperiments() {
    try {
      yield put(validateOverlapping.request());

      const payload = yield call([
        this.createExperimentService,
        this.createExperimentService.validateOverlappingExperiments
      ]);

      yield put(validateOverlapping.success(payload));
    } catch (err) {
      yield put(validateOverlapping.failure());
      Notification.error('Failed to fetch overlapped experiment');
    }
  }

  public *submitObjectiveConfigStep() {
    try {
      yield put(submitObjectivesStep.request());

      yield call([this.createExperimentService, this.createExperimentService.validateObjectiveConfigsLength]);

      yield put(submitObjectivesStep.success());
      yield put(pushNextStep());
    } catch (err) {
      yield put(submitObjectivesStep.failure());
      Notification.error(err);
    }
  }

  *afterCreate() {
    yield call([this.createExperimentService, this.createExperimentService.navigateToMainPage]);
  }
}
