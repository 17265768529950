import React, { useEffect } from 'react';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { plainToInstance } from 'class-transformer';

import { initTargetConfig, pushTargetConfigForm } from '@infrastructure/store/createExperiment/createExperimentActions';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { Summary } from '@pages/createExperiment/atoms/Summary/Summary';
import { TargetingConfig } from '@pages/createExperiment/targetConfig/TargetingConfig';
import { useForm } from '@ui/hooks/form';

type Props = {
  isCompleted?: boolean;
};

export function TargetingConfigContainer({ isCompleted }: Props) {
  const dispatch = useDispatch();

  const { targetConfig, basicInfo } = useSelector(createExperimentSelectors.getForm);
  const { experimentType } = basicInfo;

  const {
    register,
    watch,
    control,
    handleSubmit,
    resetField,
    reset,
    formState: { errors, isValid }
  } = useForm<TargetConfigParams>({
    schema: TargetConfigParams,
    defaultValues: targetConfig
  });

  useEffect(() => {
    dispatch(initTargetConfig());
  }, [dispatch]);

  useEffect(() => {
    !isCompleted && reset(targetConfig);
  }, [reset, targetConfig, isCompleted]);

  const handleNext = handleSubmit((body) => {
    const form = plainToInstance(TargetConfigParams, body);
    dispatch(pushTargetConfigForm(form));
  });

  if (isCompleted) {
    return <Summary step={CreateExperimentForm.TARGET_CONFIG} />;
  }

  return (
    <TargetingConfig
      errors={errors}
      handleSubmit={handleNext}
      register={register}
      watch={watch}
      control={control}
      resetField={resetField}
      experimentType={experimentType}
      isValid={isValid}
    />
  );
}
