import React, { useCallback, useEffect } from 'react';
import { memoize } from 'proxy-memoize';

import { ExpObjectiveConfigSummary } from '@pages/experimentDetails/components/summary/config/objective/ExpObjectiveConfigSummary';
import { ExpObjectiveConfigEditor } from '@pages/experimentDetails/components/summary/config/objective/ExpObjectiveConfigEditor';
import { useToggle } from '@ui/hooks/useToggle';
import { FormControls } from '@pages/experimentDetails/components/summary/config/atoms/FormControls';
import { useForm } from '@ui/hooks/form';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { useDispatch, useSelector } from '@ui/hooks/redux';
import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import {
  pushUpdateExpObjective,
  setSelectedObjective
} from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { RequestStage } from '@infrastructure/store/types/actions';

export function ExpObjectiveConfigContainer() {
  const dispatch = useDispatch();
  const { isActive: editorEnabled, handleOpen: openEditor, handleClose: closeEditor } = useToggle(false);

  const objectiveId = useSelector(experimentDetailsSelectors.getSelectedObjectiveId);
  const form = useSelector(memoize(experimentDetailsSelectors.getExpObjectiveConfigParams(objectiveId)));
  const regionName = useSelector(experimentDetailsSelectors.getObjectiveRegionNameById(objectiveId));
  const { objectiveUpdate: status } = useSelector(experimentDetailsSelectors.getSummaryEditorStatus);
  const controlsDisabled = useSelector(experimentDetailsSelectors.getIsSummaryEditorLoading);

  const isLoading = status === RequestStage.REQUEST;

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ExpObjectiveConfigParams>({
    schema: ExpObjectiveConfigParams,
    defaultValues: form
  });

  const handleFormSubmit = handleSubmit((params) => {
    dispatch(pushUpdateExpObjective.trigger(params));
    closeEditor();
  });

  const handleSetObjective = useCallback(
    ({ currentTarget }) => {
      dispatch(setSelectedObjective(currentTarget.value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (objectiveId) {
      reset(form);
    }

    // todo: research how to properly reset state
    // eslint-disable-next-line
  }, [objectiveId]);

  return (
    <>
      {editorEnabled ? (
        <ExpObjectiveConfigEditor
          control={control}
          register={register}
          errors={errors}
          regionName={regionName}
          usersAllocationPercent={form.usersAllocationPercent}
          handleSetObjective={handleSetObjective}
        />
      ) : (
        <ExpObjectiveConfigSummary handleSetObjective={handleSetObjective} />
      )}
      <FormControls
        openEditor={openEditor}
        closeEditor={closeEditor}
        editorEnabled={editorEnabled}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        disabled={controlsDisabled}
      />
    </>
  );
}
