export class DevRegionOption {
  value: string;
  label: string;
}

export class DevTargetingParams {
  public primaryRegion: string;
  public regions: DevRegionOption[];
  public regionsForInDev: DevRegionOption[];
  public appVersions: { value: string }[];

  static ofInitial() {
    const params = new DevTargetingParams();

    params.regions = [];
    params.regionsForInDev = [];
    params.primaryRegion = '';
    params.appVersions = [{ value: '' }];

    return params;
  }
}
