import React from 'react';
import cn from 'classnames';
import { TextInput, TextAreaInput } from 'crazy-ui-next';

import { FormComponent, getInputProps } from '@ui/hooks/form';

import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';

import styles from '../DevPhase.module.scss';

type Props = Pick<FormComponent<DevPhaseParams>, 'control' | 'register' | 'errors'>;

export function BasicInfoSection({ register, errors }: Props) {
  return (
    <ul className={styles.formSection}>
      <li>
        <p>Exp. Name:</p>
        <TextInput
          className={cn(styles.textInput, styles.wideInput)}
          {...getInputProps<DevPhaseParams>('basicInfo.experimentName', register, errors)}
        />
      </li>

      <li>
        <p>Description:</p>
        <TextAreaInput
          className={cn(styles.areaInput, styles.wideInput)}
          {...getInputProps<DevPhaseParams>('basicInfo.description', register, errors)}
        />
      </li>

      <li>
        <p>Target ARPU:</p>
        <TextInput
          className={styles.textInput}
          {...getInputProps<DevPhaseParams>('basicInfo.arpu', register, errors)}
        />
      </li>
    </ul>
  );
}
