import React from 'react';

import { FormComponent } from '@ui/hooks/form';

import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';

import { ArpuInput } from './inputs/ArpuInput';
import { ReportingArpuInput } from './inputs/ReportingArpuInput';
import { CheckPeriodInput } from './inputs/CheckPeriodInput';
import { AutomaticModeInput } from './inputs/AutomaticModeInput';
import { AppVersionInput } from './inputs/AppVersionInput';

import styles from '../../../../ExperimentDetails.module.scss';

type Props = Pick<FormComponent<ExpDefinitionParams>, 'control' | 'register' | 'errors'>;

export function ExpDefinitionEditor({ register, errors, control }: Props) {
  return (
    <ul className={styles.editorList}>
      <li>
        <ArpuInput register={register} errors={errors} />
      </li>
      <li>
        <ReportingArpuInput register={register} errors={errors} control={control} />
      </li>
      <li>
        <CheckPeriodInput control={control} />
      </li>
      <li>
        <AutomaticModeInput register={register} errors={errors} />
      </li>

      <li>
        <AppVersionInput control={control} register={register} errors={errors} />
      </li>
    </ul>
  );
}
