import { Service } from 'typedi';

import { ConfigApi } from '@infrastructure/api/ConfigApi';
import { ConfigMapper } from '@app/mappers/ConfigMapper';
import { AppConfigDto } from '@domain/models/local/AppConfig';
import { ConfigUseCase } from '@domain/useCases/ConfigUseCase';
import {
  CreateExpVariableParams,
  UpdateExpVariableParams
} from '@domain/models/experimentVariable/ExperimentVariableParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { ExpVariableMapper } from '@app/mappers/ExpVariableMapper';
import { NotFoundError } from '@app/errors/NotFoundError';
import { HistoryService } from '@infrastructure/browser/HistoryService';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { Routing } from '@infrastructure/routing';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { UniqueId } from '@domain/types';
import { GLDParamDto } from '@domain/enums/GLDParamDto';

@Service()
export class ConfigService implements ConfigUseCase {
  constructor(
    private readonly configApi: ConfigApi,
    private readonly historyService: HistoryService,
    private readonly reduxRepo: ReduxRepo
  ) {}

  navigateToMainPage() {
    this.historyService.goTo(Routing.getExpVariables());
  }

  async getConfig(): Promise<AppConfigDto> {
    const [dto, games] = await Promise.all([this.configApi.getConfig(), this.configApi.getApps()]);
    return ConfigMapper.mapDtoToCustom(dto, games);
  }

  async getApps(): Promise<GameApplicationDto[]> {
    return this.configApi.getApps();
  }

  async createVariable(body: CreateExpVariableParams): Promise<ExperimentVariableDto> {
    const user = ExpVariableMapper.mapCreateParamsToDto(body);
    return this.configApi.createVariable(user);
  }

  async updateVariable(body: UpdateExpVariableParams): Promise<ExperimentVariableDto> {
    if (!body.id) {
      throw new NotFoundError('ExperimentVariable', body.id);
    }

    const variable = this.reduxRepo.findBy(configSelectors.getVariableById(body.id));

    if (!variable) {
      throw new NotFoundError('ExperimentVariable', body.id);
    }

    const updatedBody = ExpVariableMapper.mapUpdateParamsToDto(body, variable);
    return this.configApi.updateVariable(updatedBody);
  }

  async getGlobalAppProperties(): Promise<AppPropertyDto[]> {
    return this.configApi.getGlobalAppProperties();
  }

  async updateGlobalAppProperties(properties: AppPropertyForm): Promise<AppPropertyDto[]> {
    const dto = ConfigMapper.mapAppPropertiesParamsToDto(properties);
    return this.configApi.updateGlobalAppProperties(dto);
  }

  async getControlGroups(gameId: number): Promise<ControlGroupByCountry> {
    return this.configApi.getControlGroups(gameId);
  }

  getGLDParams(gameId: UniqueId): Promise<GLDParamDto[] | null> {
    return this.configApi.getGLDParams(gameId);
  }
}
