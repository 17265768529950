import React from 'react';
import { UseFormSetFocus, UseFormWatch } from 'react-hook-form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { PercentUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/PercentUsersInput';
import { AdjustableUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/AdjustablePersentUsersInput';
import { NewUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/NewUsersInput';
import { InputGroup } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/InputGroup';
import { NextButton } from '@pages/createExperiment/atoms/NextButton/NextButton';
import { UserAllocationFormBlock } from '@pages/createExperiment/objectiveConfig/atoms/UserAllocationFormBlock';
import { FormComponent } from '@ui/hooks/form';

type Props = FormComponent<GLDConfigParams> & {
  watch: UseFormWatch<GLDConfigParams>;
  setFocus: UseFormSetFocus<GLDConfigParams>;
  gameInstalls: GameInstallsStatsDto | null;
  minUsersPerGroup: number;
  gldParams: GLDParamDto[];
};

export function GLDForm({
  handleSubmit,
  register,
  control,
  watch,
  setFocus,
  gameInstalls,
  minUsersPerGroup,
  gldParams
}: Props) {
  return (
    <form onSubmit={handleSubmit}>
      <UserAllocationFormBlock gameInstalls={gameInstalls} minUsersPerGroup={minUsersPerGroup} />
      <InputGroup register={register} control={control} watch={watch} setFocus={setFocus} gldParams={gldParams} />
      <PercentUsersInput control={control} />
      <AdjustableUsersInput register={register} />
      <NewUsersInput register={register} />

      <NextButton />
    </form>
  );
}
