import { createReducer, combineReducers } from '@reduxjs/toolkit';

import { ReducerFactory } from '@infrastructure/store/helpers/ReducerFactory';

import { fetchAppConfig, pushCreateVariable, pushUpdateVariable } from '@infrastructure/store/config/configActions';
import { AppConfig } from '@domain/models/local/AppConfig';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

const variableInitial: AppConfig.VarConfig = {
  keys: [],
  map: {},
  options: []
};

const version = createReducer('', (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.appVersion;
  })
);

const periodicity = createReducer(variableInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.periodicity;
  })
);

const experimentTypes = createReducer(variableInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.experimentTypes;
  })
);

const platforms = createReducer(variableInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.platforms;
  })
);

const queryPlaceholders = createReducer(variableInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.queryPlaceholders;
  })
);

const kpi = createReducer(variableInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.kpi;
  })
);

const variablesInitial: ExperimentVariableDto[] = [];

const variables = createReducer(variablesInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.experimentVariables;
  })
);

const gamesInitial: GameApplicationDto[] = [];

const games = createReducer(gamesInitial, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.games;
  })
);

const minUsersPerGroup = createReducer(0, (qb) =>
  qb.addCase(fetchAppConfig.success, (state, { payload }) => {
    return payload.minUsersPerGroup;
  })
);

export const config = combineReducers({
  status: ReducerFactory.makeStatus(fetchAppConfig),
  editStatus: ReducerFactory.makeStatus([pushCreateVariable, pushUpdateVariable]),
  version,
  periodicity,
  experimentTypes,
  platforms,
  queryPlaceholders,
  kpi,
  games,
  variables,
  minUsersPerGroup
});
