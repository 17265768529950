import React from 'react';
import { CheckboxInput } from 'crazy-ui-next';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/GLD/GLDForm.module.scss';

type Props = Pick<FormComponent<GLDConfigParams>, 'register'>;

export function NewUsersInput({ register }: Props) {
  return (
    <CheckboxInput
      labelText="New Users"
      className={styles.input}
      {...getInputProps<GLDConfigParams>(`newUsers`, register, {})}
    />
  );
}
