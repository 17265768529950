import { plainToInstance } from 'class-transformer';

import { Paginate } from '@domain/models/Paginate';
import { UserDto } from '@domain/models/user/UserDto';
import { MyUserDto } from '@domain/models/user/MyUserDto';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { UIConfigDto } from '@domain/models/UIConfigDto';
import { GameApplicationDto } from '@domain/models/game/GameApplicationDto';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { AppliedConfigDto } from '@domain/models/appliedConfig/AppliedConfigDto';
import { AppliedConfigStatsDto } from '@domain/models/appliedConfig/AppliedConfigStatsDto';
import { AppPropertyDto } from '@domain/models/appProperty/AppPropertyDto';
import { ExperimentObjectiveDto } from '@domain/models/experiment/ExperimentObjectiveDto';
import { WinnerConfigDto } from '@domain/models/winnerConfig/WinnerConfigDto';
import { RegionDto } from '@domain/models/RegionDto';
import { GameStatsDto } from '@domain/models/experiment/GameStatsDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { OverlappedConfigDto } from '@domain/models/experiment/OverlappedConfigDto';

export class ResponseMapper {
  /** To convert requests with pagination */
  static mapToPage<T>(res: Paginate<object>, mapFunc: (plainObject: object) => T): Paginate<T> {
    return {
      totalNumber: res.totalNumber,
      moreAvailable: res.moreAvailable,
      lastPageIndex: res.lastPageIndex,
      thisPageItems: res.thisPageItems?.map(mapFunc) || []
    };
  }

  static mapToUIConfigDto(plainObject: object) {
    return plainToInstance(UIConfigDto, plainObject);
  }

  static mapToMyUserDto(plainObject: object) {
    return plainToInstance(MyUserDto, plainObject);
  }

  static mapToUserDto(plainObject: object) {
    return plainToInstance(UserDto, plainObject);
  }

  static mapToExperimentObjectiveDto(plainObject: object) {
    return plainToInstance(ExperimentObjectiveDto, plainObject);
  }

  static mapToExperimentDto(plainObject: object) {
    return plainToInstance(ExperimentDto, plainObject);
  }

  static mapToPageExperimentDto(plainObject: Paginate<object>) {
    return ResponseMapper.mapToPage(plainObject, ResponseMapper.mapToExperimentDto);
  }

  static mapToGameApplicationDto(plainObject: object) {
    return plainToInstance(GameApplicationDto, plainObject);
  }

  static mapToExperimentVariableDto(plainObject: object) {
    return plainToInstance(ExperimentVariableDto, plainObject);
  }

  static mapToApplicationPropertyDto(plainObject: object) {
    return plainToInstance(AppPropertyDto, plainObject);
  }

  static mapToAppliedConfigDto(plainObject: object) {
    return plainToInstance(AppliedConfigDto, plainObject);
  }

  static mapToAppliedConfigStatsDto(plainObject: object) {
    return plainToInstance(AppliedConfigStatsDto, plainObject);
  }

  static mapToWinnerConfigDto(plainObject: object) {
    return plainToInstance(WinnerConfigDto, plainObject);
  }

  static mapToRegionDto(plainObject: object) {
    return plainToInstance(RegionDto, plainObject);
  }

  static mapToGameStatsDto(plainObject: object) {
    return plainToInstance(GameStatsDto, plainObject);
  }

  static mapToGameInstallsStatsDto(plainObject: object) {
    return plainToInstance(GameInstallsStatsDto, plainObject);
  }

  static mapToOverlappedConfigDto(plainObject: object) {
    return plainToInstance(OverlappedConfigDto, plainObject);
  }
}
