import React, { useCallback, useState, useEffect } from 'react';
import { useForm } from '@ui/hooks/form';
import { DropdownOption } from 'crazy-ui-next';
import { IsNotEmpty, Matches, validate } from 'class-validator';
import { useController, UseFormWatch } from 'react-hook-form';

import { createSimpleOptions } from '@infrastructure/utils/createOptions.utils';
import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { SelectCreatable } from '@components/lib/SelectCreatable/SelectCreatable';

class Param {
  @Matches(/^[A-Za-z0-9_]+$/, { message: 'Value must contain letters, digits and underscore only' })
  @IsNotEmpty()
  value: string;
}

type Props = {
  handleAddParam: (body: Param) => void;
  watch: UseFormWatch<GLDConfigParams>;
  gldParams: GLDParamDto[];
};

export function AddParamInput({ handleAddParam, watch, gldParams }: Props) {
  const [options, setOptions] = useState([] as DropdownOption[]);
  const params = watch('params');
  const chosenOptions = params.map((param) => param.value);
  const formattedParams = params.map(({ value }) => value.toLowerCase());

  const {
    control,
    handleSubmit,
    resetField,
    setError,
    formState: { errors }
  } = useForm<Param>({ schema: Param, defaultValues: { value: '' } });
  const { field } = useController({ control, name: 'value' });

  const handleAdd = handleSubmit((body) => {
    if (formattedParams.includes(body.value.toLowerCase())) {
      setError('value', {
        type: 'custom',
        message: 'Param name should be uniq'
      });
      return;
    }

    handleAddParam(body);
  });

  const handleChangeSelect = useCallback(
    (option: DropdownOption) => {
      field.onChange(option.value);
      handleAdd();
      resetField('value', { keepError: true });
    },
    [field, handleAdd, resetField]
  );

  const handleCreateOption = useCallback(
    (param: string) => {
      // create & add new option to the list only in case it pass validation
      const newParam = new Param();
      newParam.value = param;
      validate(newParam).then((errorList) => {
        if (!errorList.length) {
          const newOption = { value: param, label: param };

          setOptions((prev) => [...prev, newOption]);
        }
      });

      field.onChange(param);
      handleAdd();
      resetField('value', { keepError: true });
    },
    [field, handleAdd, resetField]
  );

  useEffect(() => {
    const defaultOptions: DropdownOption[] = createSimpleOptions(gldParams.map((param) => param.name));

    setOptions(defaultOptions);
  }, [gldParams, setOptions]);

  return (
    <SelectCreatable
      name="gldParams"
      labelText="Parameters"
      placeholder="Select or create parameter"
      options={options}
      handleChange={handleChangeSelect}
      handleCreateOption={handleCreateOption}
      value={field.value}
      error={errors?.value?.message}
      isMulti={false}
      isClearable
      disabledOptions={chosenOptions}
    />
  );
}
