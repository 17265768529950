import React, { useCallback } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import { TextInput, InputLabel } from 'crazy-ui-next';

import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';
import { getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/targetConfig/TargetingConfig.module.scss';

type Props = {
  control: Control<TargetConfigParams>;
  register: UseFormRegister<TargetConfigParams>;
};

const maxInputsLength = 5;

export function VersionsInput({ control, register }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'versions'
  });

  const onAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        className={styles.multiInputEl}
        key={field.id}
        {...getInputProps<TargetConfigParams>(`versions.${index}.value`, register, {})}
      />
    );
  });

  return (
    <>
      <InputLabel labelText="Versions" />
      <MultiTextInputContainer
        inputs={inputs}
        onAddItem={onAdd}
        onRemoveItem={onRemove}
        maxItems={maxInputsLength}
        className={styles.inputWrapper}
      />
    </>
  );
}
